<div class="text-end">
    <ul class="dashrgt">
        <!-- <li> -->
            <!-- <div class="chartbox">
                <span>Your profile is incomplete</span>
                <div class="chartimg">
                    <em>95%</em>
                    <img src="assets/images/round.png" alt="Logo">
                </div>
            </div> -->
        <!-- </li> -->
        <li class="ms-3">
            <div  class="cursor-pointer" [matMenuTriggerFor]="menu" >
            <ng-container *ngIf="company_logo; else noComlogo">
                <div class="profileimg" >
                    <img [src]="company_logo"  alt="user">
                </div>
            </ng-container>
            <ng-template #noComlogo >
                <div class="profileimg">
                    <img src="assets/images/user.jpg"  alt="user">
                </div>
            </ng-template>
            </div>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="changePassword(company_id)">{{translations?.changePassword}}</button>
                <button mat-menu-item (click)="logout()" >{{translations?.logout}}</button>    
           </mat-menu>
        </li>
    </ul>
</div>