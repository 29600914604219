import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ChangePasswordComponent } from './modal/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  company_logo!:string;
  lang:string = 'en';
  company_id!:string;

  currentLanguage$?: Subscription
  translations: any;

  constructor(
    private authService:AuthService,
    private router:Router,
    private dialog: MatDialog,
    private langService: LanguageTranslateService,
  
  ){
    this.authService.com_logo$.subscribe((img:any)=>{
        this.company_logo = img;        
    });
    this.authService.getCompanyUserAccountDetails().then(res=>{
     console.log(res);
     this.company_id = res?.company_det?._id;
    })
 
    this.lang = localStorage.getItem('lang') ?? 'en';


  
  }
  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
        return new Promise<boolean>(async (resolve, reject) => {
          await this.langService.loadTranslations('layout', 'ProfileComponent')?.then((res: any) => {
            this.translations = res;
            resolve(true);
          }).catch((err: any) => {
            console.error(`Failed to load translations`, err);
            reject(false);
          });
        })
      });
  }

  routeToComProfile(){
    this.router.navigate([this.lang, 'settings', 'company', 'profile'])
  }

  changePassword(empId: any) {
    this.dialog.open(ChangePasswordComponent, {
      data: empId,
      width: '500px',
      autoFocus: false,
      disableClose: true,
    }).afterClosed().subscribe((res: any) => {
      if (res?.status == 'success') {
       
      }
    })
  }

  logout(){
    this.authService.logout();
  }
}
