import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Title } from 'chart.js';
import { Editor, toHTML } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription, debounceTime } from 'rxjs';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-announce-dialog',
  templateUrl: './announce-dialog.component.html',
  styleUrls: ['./announce-dialog.component.css']
})
export class AnnounceDialogComponent {
  lang:any = 'en';
  announceForm!: UntypedFormGroup;
  announce_data!:any[];
  announcement_comment_data!:any[];
  publishStatusMaster!:any[];
  Global = Global;
  announceId:any;
  isOn: boolean = false;
  announcement_title: any;
  currentLanguage$?: Subscription;
  translations: any;
  editActionId!: String;
  editor!: Editor;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private announcementService: AnnouncementService,
    private spinner : NgxSpinnerService,
    private dialogRef: MatDialog, 
    private langService: LanguageTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.announceForm = formBuilder.group({
      title: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      publish_status: [null, Validators.compose([])],
      // post_by: [null, Validators.compose([Validators.required])]
    });

    this.publishStatusMaster = [
      { 'value': 'private', 'description': 'Private' },
      { 'value': 'published', 'description': 'Published' },
    ];

}

ngOnInit(): void {
  this.editor = new Editor();
  this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
this.lang = this.langService.getLangLocalStorage();
    return new Promise<boolean>(async (resolve, reject) => {
      await this.langService.loadTranslations('announcement', 'AnnouncementComponent')?.then((res: any) => {
        this.translations = res;
        resolve(true);
      }).catch((err: any) => {
        console.error(`Failed to load translations`, err);
        reject(false);
      });
    })
  });

  if(this.data?.type){
    console.log(this.data)
    this.editActionId = '';
  }else{
    this.editActionId = this.data?.editActionId?._id;
    this.getEdit()
  }
  this.announceForm.valueChanges.pipe(debounceTime(500)).subscribe(value => {
    const html = toHTML(value?.description);
    this.announceForm.patchValue({
      description: html,
    });
  });
}

ngOnDestroy(): void {
  this.editor.destroy();
}

getPublishStatusMaster(event: any) {
  this.announceForm.patchValue({
    publish_status: event?.value?.value,
  })
}

create(event: any) {
  this.announceForm.markAllAsTouched();
  Global.scrollToQuery("p.text-danger")
 if (this.announceForm.valid) {
    event.target.classList.add('btn-loading');
    
    this.announcementService.createAnnouncement({
      'title': this.announceForm.value.title,
      'description': this.announceForm.value.description,
      'publish_status': this.announceForm.value.publish_status,
      // 'post_by': this.announceForm.value.post_by ?? "Company",
      
    }).then(res => {
      if (res.status == 'success') {
        this.toastr.success(res.message);
      this.closeDialog();
      } else if (res.status == 'val_err') {
        this.toastr.error(Global.showValidationMessage(res.val_msg));
      } else {
        this.toastr.error(res.message);
      }

      event.target.classList.remove('btn-loading');
    }, (err) => {
      event.target.classList.remove('btn-loading');
      this.toastr.error(Global.showServerErrorMessage(err));
    });
  }
}

getEdit() {
  let item = this.data?.editData
  // console.log($("#faq-submit-section"));
  console.log(item)
  this.editActionId = item._id;
  this.announceForm.patchValue({
    title: item.title,
    publish_status: item.publish_status,
    description: item.description,
    post_by:item.post_by
  });

 Global.scrollToQuery("#announce-form")
}

update(event: any){
  this.announceForm.markAllAsTouched();
  Global.scrollToQuery("p.text-danger")
 if (this.announceForm.valid) {
    event.target.classList.add('btn-loading');
    
    this.announcementService.updateAnnouncement({
      '_id': this.editActionId,
      'title': this.announceForm.value.title,
      'description': this.announceForm.value.description,
      'publish_status': this.announceForm.value.publish_status,
      // 'post_by': this.announceForm.value.post_by ?? "Company"
      
    }).then(res => {
      if (res.status == 'success') {
        this.toastr.success(res.message);
        this.closeDialog();
      } else if (res.status == 'val_err') {
        this.toastr.error(Global.showValidationMessage(res.val_msg));
      } else {
        this.toastr.error(res.message);
      }

      event.target.classList.remove('btn-loading');
    }, (err) => {
      event.target.classList.remove('btn-loading');
      this.toastr.error(Global.showServerErrorMessage(err));
    });
  }
}

closeDialog(){
  this.dialogRef.closeAll();
}
}

