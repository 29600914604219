import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import PaginationOptions from 'src/app/models/PaginationOptions';
import { AnnouncementService } from 'src/app/services/announcement.service';
import * as Global from 'src/app/shared/globals';
import Swal from 'sweetalert2';
import { CommentViewModalComponent } from './comment-view-modal/comment-view-modal.component';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { AnnounceDialogComponent } from './announce-dialog/announce-dialog.component';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent {
  lang:any = 'en';
  editActionId: String;
  announceForm: UntypedFormGroup;
  announce_data!:any[];
  announcement_comment_data!:any[];
  publishStatusMaster:any[];
  Global = Global;
  announceId:any;
  paginationOptions: PaginationOptions;
  isOn: boolean = false;
  announcement_title: any;
  currentLanguage$?: Subscription;
  translations: any;
  
  constructor(
    public formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private toastr: ToastrService,
    private router: Router,
    private announcementService: AnnouncementService,
    private spinner : NgxSpinnerService,
    private dialogRef: MatDialog, 
    private langService: LanguageTranslateService
  ) { 
    this.announceForm = formBuilder.group({
      title: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      publish_status: [null, Validators.compose([])],
      // post_by: [null, Validators.compose([Validators.required])]
    });

    this.publishStatusMaster = [
      { 'value': 'private', 'description': 'Private' },
      { 'value': 'published', 'description': 'Published' },
    ];

    this.paginationOptions = {
      hasNextPage: false,
      hasPrevPage: false,
      limit: Global.DataTableLength,
      nextPage: null,
      page: 1,
      pagingCounter: 1,
      prevPage: null,
      totalDocs: 0,
      totalPages: 1,
    };

    this.editActionId = '';
    this.fetch();
    
  }

  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('announcement', 'AnnouncementComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }

  openDialog(data:any) {
    if(data == "create"){
    const dialogRef = this.dialogRef.open(AnnounceDialogComponent, {
      width: 'fit-content',
      minWidth:"500px",
      disableClose: true,
      autoFocus: false,
      data: {type:data}
    });

    dialogRef.afterClosed().subscribe(result => {
     this.fetch();
    });
  }else{
    //  console.log(data)
    const dialogRef = this.dialogRef.open(AnnounceDialogComponent, {
      width: 'fit-content',
      minWidth:"500px",
      disableClose: true,
      autoFocus: false,
      data: {editData:data }
    });

    dialogRef.afterClosed().subscribe(result => {
    this.fetch();
      
    });
    
  
  }
}
 
  
  getPublishStatusMaster(event: any) {
    this.announceForm.patchValue({
      publish_status: event?.value?.value,
    })
  }

  create(event: any) {
    this.announceForm.markAllAsTouched();
    Global.scrollToQuery("p.text-danger")
   if (this.announceForm.valid) {
      event.target.classList.add('btn-loading');
      
      this.announcementService.createAnnouncement({
        'title': this.announceForm.value.title,
        'description': this.announceForm.value.description,
        'publish_status': this.announceForm.value.publish_status,
        // 'post_by': this.announceForm.value.post_by ?? "Company",
        
      }).then(res => {
        if (res.status == 'success') {
          this.toastr.success(res.message);
          this.cancelEntry();

           this.fetch();
        } else if (res.status == 'val_err') {
          this.toastr.error(Global.showValidationMessage(res.val_msg));
        } else {
          this.toastr.error(res.message);
        }

        event.target.classList.remove('btn-loading');
      }, (err) => {
        event.target.classList.remove('btn-loading');
        this.toastr.error(Global.showServerErrorMessage(err));
      });
    }
  }

  getEdit(item: any) {
    // console.log($("#faq-submit-section"));
    console.log(item)
    this.editActionId = item._id;
    this.announceForm.patchValue({
      title: item.title,
      publish_status: item.publish_status,
      description: item.description,
      post_by:item.post_by
    });

   Global.scrollToQuery("#announce-form")
  }

  update(event: any){
    this.announceForm.markAllAsTouched();
    Global.scrollToQuery("p.text-danger")
   if (this.announceForm.valid) {
      event.target.classList.add('btn-loading');
      
      this.announcementService.updateAnnouncement({
        '_id': this.editActionId,
        'title': this.announceForm.value.title,
        'description': this.announceForm.value.description,
        'publish_status': this.announceForm.value.publish_status,
        // 'post_by': this.announceForm.value.post_by ?? "Company"
        
      }).then(res => {
        if (res.status == 'success') {
          this.toastr.success(res.message);
          this.cancelEntry();

           this.fetch();
        } else if (res.status == 'val_err') {
          this.toastr.error(Global.showValidationMessage(res.val_msg));
        } else {
          this.toastr.error(res.message);
        }

        event.target.classList.remove('btn-loading');
      }, (err) => {
        event.target.classList.remove('btn-loading');
        this.toastr.error(Global.showServerErrorMessage(err));
      });
    }
  }
 
  fetch(page: any = null) {
    return new Promise((resolve, reject) => {
      if (page != null) {
        this.paginationOptions.page = page;
      }

      this.spinner.show();
      this.announcementService.fetchAnnouncementData({
        'pageno': this.paginationOptions.page,
         'perpage':this.paginationOptions.limit
      }).then(res => {
        if (res.status == 'success') {
          //  console.log(res);
          this.announce_data = res.post_data.docs;
          
          console.log(this.announce_data.length)
        
           this.paginationOptions = {
            hasNextPage: res.post_data.hasNextPage,
            hasPrevPage: res.post_data.hasPrevPage,
            limit: res.post_data.limit,
            nextPage: res.post_data.nextPage,
            page: res.post_data.page,
            pagingCounter: res.post_data.pagingCounter,
            prevPage: res.post_data.prevPage,
            totalDocs: res.post_data.totalDocs,
            totalPages: res.post_data.totalPages,
          };
        } else {
          this.toastr.error(res.message);

          this.announce_data = [];
          this.paginationOptions = {
            hasNextPage: false,
            hasPrevPage: false,
            limit: Global.DataTableLength,
            nextPage: null,
            page: 1,
            pagingCounter: 1,
            prevPage: null,
            totalDocs: 0,
            totalPages: 1,
          };
        }

        this.spinner.hide();
        
        resolve(true);
      }, (err) => {
        this.announce_data = [];
        this.paginationOptions = {
          hasNextPage: false,
          hasPrevPage: false,
          limit: Global.DataTableLength,
          nextPage: null,
          page: 1,
          pagingCounter: 1,
          prevPage: null,
          totalDocs: 0,
          totalPages: 1,
        };

        this.spinner.hide();
        this.toastr.error(Global.showServerErrorMessage(err));
        
        resolve(true);
      });
    });
  }

  searchTable(s:any){
    this.announcementService.fetchAnnouncementData({
      'pageno': this.paginationOptions.page,
      'searchkey': s
    }).then(res => {
      if (res.status == 'success') {
        this.announce_data = res.post_data.docs;
        this.paginationOptions = {
          hasNextPage: res.post_data.hasNextPage,
          hasPrevPage: res.post_data.hasPrevPage,
          limit: res.post_data.limit,
          nextPage: res.post_data.nextPage,
          page: res.post_data.page,
          pagingCounter: res.post_data.pagingCounter,
          prevPage: res.post_data.prevPage,
          totalDocs: res.post_data.totalDocs,
          totalPages: res.post_data.totalPages,
        };
      } else {
        this.toastr.error(res.message);

        this.announce_data = [];
        this.paginationOptions = {
          hasNextPage: false,
          hasPrevPage: false,
          limit: Global.DataTableLength,
          nextPage: null,
          page: 1,
          pagingCounter: 1,
          prevPage: null,
          totalDocs: 0,
          totalPages: 1,
        };
      }

      this.spinner.hide();
      
      // resolve(true);
    }, (err) => {
      this.announce_data = [];
      this.paginationOptions = {
        hasNextPage: false,
        hasPrevPage: false,
        limit: Global.DataTableLength,
        nextPage: null,
        page: 1,
        pagingCounter: 1,
        prevPage: null,
        totalDocs: 0,
        totalPages: 1,
      };

      this.spinner.hide();
      this.toastr.error(Global.showServerErrorMessage(err));
      
      // resolve(true);
    });
  }


  cancelEntry(){
    this.editActionId = '';
    this.announceForm.reset();
  }

  toggleStatus(item:any){
    
    this.changeStatus(item)
   }
  changeStatus(item: any) {
    console.log(item.publish_status,'sdsd');
    this.announcementService.updateAnnouncementStatus({
      'title': item.title,
      '_id': item._id,
      'publish_status': (item.publish_status == "published") ? 'private' : 'published',
    }).then(res => {
      if (res.status == 'success') {
        this.toastr.success(res.message);
        item.publish_status = (item.publish_status == "published") ? 'private' : 'published';
      } else {
        this.toastr.error(res.message);
      }

    }, (err) => {
      this.toastr.error("Internal server error occured. Please try again later.");
    });
    // this.fetch();
  }

  

  viewComment(item: any) {
    // this.announceId = item?.post_id;
    // // $('#viewmodalbutton').click();
    // this.fetchAnnouncementComment();
    // this.announcement_title = item.title;
    //   console.log(item);

    this.dialogRef.open(CommentViewModalComponent, {
      width: 'fit-content',
      minWidth:"500px",
      disableClose: true,
      autoFocus: false,
      data: {item}   ,
    }).afterClosed().subscribe((res: any) => {
      
    })
  }


  deleteItem(item: any) {
    Swal.fire({
      title: 'Are you sure want to remove?',
      text: 'You will not be able to recover this data!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.announcementService.deleteAnnouncement({
          '_id': item._id,
        }).then(res => {
          if (res.status == 'success') {
            this.toastr.success(res.message);
            this.fetch();
          } else {
            this.toastr.error(res.message);
          }
        }, (err) => {
          this.toastr.error(Global.showServerErrorMessage(err));
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  fetchAnnouncementComment(page: any = null) {
    return new Promise((resolve, reject) => {
      if (page != null) {
        this.paginationOptions.page = page;
      }

      this.spinner.show();
      // console.log(this.announceId);
      this.announcementService.fetchAnnouncementCommentData({
        'pageno': this.paginationOptions.page,
        'parent_id': this.announceId,
        
      }).then(res => {
        if (res.status == 'success') {
          //  console.log(res);
          this.announcement_comment_data = res.data.docs;

            this.paginationOptions = {
            hasNextPage: res.data.hasNextPage,
            hasPrevPage: res.data.hasPrevPage,
            limit: res.data.limit,
            nextPage: res.data.nextPage,
            page: res.data.page,
            pagingCounter: res.data.pagingCounter,
            prevPage: res.data.prevPage,
            totalDocs: res.data.totalDocs,
            totalPages: res.data.totalPages,
          };
        } else {
          this.toastr.error(res.message);

          this.announcement_comment_data = [];
          this.paginationOptions = {
            hasNextPage: false,
            hasPrevPage: false,
            limit: Global.DataTableLength,
            nextPage: null,
            page: 1,
            pagingCounter: 1,
            prevPage: null,
            totalDocs: 0,
            totalPages: 1,
          };
        }

        this.spinner.hide();
        
        resolve(true);
      }, (err) => {
        this.announcement_comment_data = [];
        this.paginationOptions = {
          hasNextPage: false,
          hasPrevPage: false,
          limit: Global.DataTableLength,
          nextPage: null,
          page: 1,
          pagingCounter: 1,
          prevPage: null,
          totalDocs: 0,
          totalPages: 1,
        };

        this.spinner.hide();
        this.toastr.error(Global.showServerErrorMessage(err));
        
        resolve(true);
      });
    });
  }

  routeToanncDetails(id:any){
    this.router.navigate([this.lang,'announcement-detail',id])
  }

  

}
