


<div class="info-fluid height100 m-4">

    <div class="container mb-4">
        <div class="taxformmain" *ngIf="isLinkValid && !isFormSubmit">
          
          
          <div class="content" >
            <div class="boxshodo borderredius-0">
                <div class="p-15 modal-body py-4 border-bottom">
                  <div class="header text-center mb-4">
                    <h4>OFFER LETTER</h4>
                   
                  </div>
                  <br>
                  <div  class="d-flex justify-content-center">
                    
                  <ol>
                   
                      <li>{{company_name}}</li>
                      <li>{{com_address}}</li>
                      <p>{{date}}</p>
    
                      <p>
                        <li>{{emp_name}}</li>
                        <li>{{emp_address}}</li>
                      </p>
                  
    
                    
                      <p>Dear {{emp_name}},</p><br>
                      <li>We are pleased to offer you the position of {{job_title}} at {{company_name}}, as discussed during your interview.</li>
                        <li> We believe your skills and experience make you an ideal fit for our team and contribute to the company's growth.</li>
                    
                    <br>
                    
                      <p>Below are the details of the offer:</p>
                      <ul>
                        <li>Job Title: {{job_title}} </li>
                        <li>Department: {{department}}</li>
                         <li>Expected Joining Date: {{expected_date}} </li>
                        <li>Salary: {{salary}}</li>
                        <p>Benefits:</p>
                        <ng-container *ngFor="let data of benefits">
                        <li>-{{data}}</li>
                        </ng-container>
                      </ul>
                      <br>
                    
                 
                      <p>Please confirm your acceptance of this offer by signing and returning one copy of this letter to us by {{deadline_date}}. We also require a start date confirmation.
                      </p>
                      <li>If you have any questions or concerns, please do not hesitate to contact us.</li>
                      <li>Congratulations on your new role, and we look forward to welcoming you to {{company_name}}!</li>
                  
    
                    <li>
                      <p>Sincerely,</p>
    
                      <p>{{hr_name}} </p>
                      <p>HR</p>
                      <p>{{company_name}}</p>
                    </li>
                    <form [formGroup]="acceptanceForm">
                    <h5>Acceptance:</h5>
                <p>I, {{emp_name}}, accept the offer of employment with {{company_name}} as outlined above. I confirm my start date as {{expected_date}}.</p>
                <p>Signature:</p>
                <input type="text" formControlName="signature" class="form-control" readonly
                [ngClass]="{'is-invalid': acceptanceForm.get('signature')?.hasError('required') && acceptanceForm.get('signature')?.touched}">
              <span class="invalid-feedback" *ngIf="acceptanceForm.get('signature')?.hasError('required') && acceptanceForm.get('signature')?.touched">
                Signature is required.
              </span>
              <div class="signature-container">
                <signature-pad [options]="signaturePadOptions" (onEndEvent)="drawComplete()"></signature-pad>
                <span style="cursor: pointer;" (click)="clearPad()">Clear</span>
              </div>
              <br><br>
              <p>
                Upload Signature Image: 
                <input type="file" (change)="onSignatureImageChange($event)" class="form-control"
                  [ngClass]="{'is-invalid': acceptanceForm.get('signatureImage')?.hasError('required') && acceptanceForm.get('signatureImage')?.touched}">
                <span class="invalid-feedback" *ngIf="acceptanceForm.get('signatureImage')?.hasError('required') && acceptanceForm.get('signatureImage')?.touched">
                  Signature image is required.
                </span>
              </p>
            
            </form>
                <p>Date: {{date}}</p>
                  </ol>
                  </div>
                  
            </div>
         </div>
       </div>
    
       <div class="row  mt-2" >
        <div class="col-12 col-sm-12">
          
          <button class="btn button-solide" (click)="acceptOffer(true)">
            <span> ACCEPT</span>
          </button>&nbsp;&nbsp;
    
          <button class="btn button-solide" (click)="acceptOffer(false)" >
            <span>REJECT</span>
          </button>
        </div>
      </div>
    </div>
    
    </div>
    </div>
    <div *ngIf="!isLinkValid && !isLoading" class="text-center">
        <h2 class="mt-5 pt-5">This Invite link is Expired!</h2>
      </div>
      <div *ngIf="isFormSubmit" class="text-center">
        <h2 class="mt-5 pt-5">Thanks For Your Response!</h2>
      </div>
    
    
    