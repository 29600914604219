<aside class="app-sidebar">
    <div class="app-sidebar__header">
        <div class="main-logo">
            <img class="static-text" src="assets/images/logo.png" alt="">
        </div>
    </div>
    <div class="main-sidemenu">
        <ul>
            <li [class.active]="isActive('dashboard')">
                <span class="active" (click)="routerChange('dashboard')">
                    <img src="assets/images/dashbord.png" alt="dashbord">
                    <h4 class="static-text">{{translations?.dashboard}}</h4>
                </span>
            </li>
           
            <li [class.active]="isActive('employees')">
                <span (click)="routerChange('employees')">
                    <img src="assets/images/user.png" alt="dashbord">
                    <h4 class="static-text">{{translations?.employee}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('attendance')">
                <span (click)="routerChange('attendance')">
                    <img src="assets/images/attendance-icon.png" alt="dashbord">
                    <h4 class="static-text">{{translations?.attendance}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('payrolls')">
                <span (click)="routerChange('payrolls')">
                    <img src="assets/images/payroll-icon.png" alt="dashbord">
                    <h4 class="static-text">{{translations?.payroll}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('salary-revision')">
                <span (click)="routerChange('salary-revision')">
                    <img src="assets/images/payroll-icon.png" alt="salary-revision">
                    <h4 class="static-text">{{translations?.salaryRevision}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('shift')">
                <span (click)="routerChange('shift')">
                    <img src="assets/images/shift-icon.png" alt="shift">
                    <h4 class="static-text">{{translations?.shift}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('leave')">
                <span (click)="routerChange('leave')">
                    <img src="assets/images/icon6.png" alt="leave">
                    <h4 class="static-text">{{translations?.leave}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('approval')">
                <span (click)="routerChange('approval')">
                    <img src="assets/images/icon7.png" alt="approval">
                    <h4 class="static-text">{{translations?.approval}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('appraisal')">
                <span (click)="routerChange('appraisal')">
                    <img src="assets/images/icon8.png" alt="appraisal">
                    <h4 class="static-text">{{translations?.appraisal}}</h4>
                </span>
            </li>
            <li [class.active]="isActive('reports')">
                <span (click)="routerChange('reports')">
                    <img src="assets/images/icon9.png" alt="reports">
                    <h4 class="static-text">{{translations?.reports}}</h4>
                </span>
            </li>

            <li [class.active]="isActive('announcement')">
                <span (click)="routerChange('announcement')">
                    <img src="assets/images/calendar.png" alt="announcement">
                    <h4 class="static-text">{{translations?.announcement}}</h4>
                </span>
            </li>

            <li [class.active]="isActive('billings')">
                <span (click)="routerChange('billings')">
                    <img src="assets/images/calendar.png" alt="billings">
                    <h4 class="static-text">{{translations?.billings}}</h4>
                </span>
            </li>

            <li [class.active]="isActive('vault')" #menuItem>
                <span (click)="routerChange('vault')">
                    <img src="assets/images/doc_vault.png" alt="vault">
                    <h4 class="static-text">{{translations?.vault}}</h4>
                </span>
            </li>

            <li [class.active]="isActive('settings')">
                <span (click)="routerChange('settings')">
                    <img src="assets/images/gearr.png" alt="settings">
                    <h4 class="static-text">{{translations?.settings}}</h4>
                </span>
            </li>
            <li>
                <span (click)="logout()">
                    <img src="assets/images/lock-fill-svg.png" alt="logout">
                    <h4 class="static-text">{{translations?.logOut}}</h4>
                </span>
            </li>
        </ul>
    </div>
</aside>
